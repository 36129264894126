<template>
    <LiefengContent :isBack="true" @backClick="backClick">
        <template v-slot:title>积分商城商品管理</template>
        <template v-slot:toolsbarLeft>
            <!-- <Select v-model="searchModel" style="width: 120px; margin-right: 5px" placeholder="全部模式" clearable>
                <Option :value="item.dictKey" v-for="(item, index) in searchList" :key="index">{{ item.dictValue }}</Option>
            </Select> -->
            <!-- <Button
                type="info"
                @click="
                    () => {
                        getList()
                    }
                "
            >
                查询
            </Button> -->
        </template>
        <template v-slot:toolsbarRight>
            <Button type="info" @click="append({})">新增</Button>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="tableColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>

            <!-- 新增修改弹窗 -->
            <LiefengModal
                class="map"
                :fullscreen="false"
                :title="currentFormData.pointsGoodsId && currentFormData.pointsGoodsId != '' ? '修改' : '新增'"
                :value="saveStatus"
                @input="saveStatusFn"
                :width="1000"
                height="500px"
            >
                <template v-slot:contentarea>
                    <div style="height: 100%; overflow: hidden">
                        <Form :model="currentFormData" :label-width="120" :rules="validateForm" class="form" ref="form">
                            <FormItem label="商品名称" prop="goodsId">
                                <elem-select
                                    style="width: 300px"
                                    placeholder="请选择商品"
                                    :remotely="{
                                        api: `/gateway/apps/o2o/api/o2o/goods/getGoodsDetail`,
                                        search: 'goodsId',
                                        id: 'goodsId',
                                        value: 'goodsName',
                                    }"
                                    :multiple="false"
                                    :value="currentFormData.goodsId"
                                    @on-change="currentFormData.goodsId = $event.value"
                                    ref="eleselect"
                                ></elem-select>
                            </FormItem>
                            <FormItem label="兑换描述">
                                <Input type="textarea" rows="8" v-model.trim="currentFormData.goodsDesc" placeholder="请描写兑换描述"></Input>
                            </FormItem>
                            <FormItem label="模式名称">
                                <RadioGroup v-model="currentFormData.poolId" @on-change="changeModel">
                                    <Radio v-for="(item, index) in selectList" :key="index" :label="item.poolId">{{ item.poolName }}</Radio>
                                </RadioGroup>
                            </FormItem>
                            <FormItem label="可兑等级" v-if="currentFormData.poolId && currentFormData.poolId != '' && pointList && pointList.length">
                                <RadioGroup v-model="currentFormData.pointsLevelId">
                                    <Radio v-for="(item, index) in pointList" :key="index" :label="item.levelId">{{ item.levelName }}</Radio>
                                </RadioGroup>
                            </FormItem>
                            <FormItem label="兑换价格">
                                <Input style="width: 300px" type="Number" v-model.trim="currentFormData.redeemPrice" placeholder="请填写数字"></Input>
                                <span style="margin-left: 5px">元</span>
                            </FormItem>
                            <FormItem label="每人可兑数量">
                                <Input style="width: 300px" type="Number" v-model.trim="currentFormData.userRedeemMaxCount" placeholder="请填写数字"></Input>
                                <span style="margin-left: 5px">件</span>
                            </FormItem>

                            <!-- <FormItem label="现场图">
                                <LiefengUpload
                                    ref="LiefengUploadIcon"
                                    v-if="showImage"
                                    accept=".jpg,.png,.gif,.jpeg"
                                    :format="['jpg', 'png', 'jpeg', 'gif']"
                                    :defaultList="currentFormData.img"
                                    :showView="true"
                                    :multiple="true"
                                    :fileLength="1000"
                                ></LiefengUpload>
                            </FormItem> -->
                        </Form>
                    </div>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="saveStatusFn(false)">取消</Button>
                    <Button type="primary" @click="submit">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/test15minmanage')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUpload from "@/components/LiefengUpload"
import ElemSelect from "../../shop/components/elem-select.vue"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengUpload,
        ElemSelect,
    },
    data() {
        return {
            searchModel: "",
            searchList: [],
            tableData: [],
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            tableColumns: [
                {
                    title: "模式名称",
                    width: 250,
                    key: "poolName",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: { color: "blue", cursor: "pointer" },
                                on: {
                                    click: () => {
                                        this.select(params.row.pointsGoodsId)
                                    },
                                },
                            },
                            params.row.poolName
                        )
                    },
                },
                {
                    title: "可兑等级",
                    minWidth: 200,
                    key: "levelName",
                    align: "center",
                },
                {
                    title: "商品名称",
                    minWidth: 200,
                    key: "goodsDesc",
                    align: "center",
                },
                {
                    title: "供应商名称",
                    key: "supplierName",
                    width: 170,
                    align: "center",
                },
                {
                    title: "单价（元）",
                    key: "marketPrice",
                    width: 170,
                    align: "center",
                },
                {
                    title: "库存",
                    key: "goodsNumber",
                    width: 170,
                    align: "center",
                },
                {
                    title: "上下架",
                    key: "gmtCreate",
                    width: 170,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h("i-switch", {
                                props: {
                                    value: params.row.status,
                                    size: "large",
                                    "true-value": "1",
                                    "false-value": "2",
                                },
                                scopedSlots: {
                                    open: () => h("span", "上架"),
                                    close: () => h("span", "下架"),
                                },
                                on: {
                                    "on-change": val => {
                                        this.changeSwitch(val, params.row.id)
                                    },
                                },
                            }),
                        ])
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    width: 200,
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.select(params.row.pointsGoodsId)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "修改" //1001
                                ),
                                // h(
                                //     "span",
                                //     {
                                //         on: {
                                //             click: async () => {
                                //                this.deleteData(params.row.id)
                                //             },
                                //         },
                                //         style: {
                                //             width: "65px",
                                //             marginRight: "10px",
                                //             textAlign: "center",
                                //             color: "#2d8cf0",
                                //             cursor: "pointer",
                                //             userSelect: "none",
                                //         },
                                //     },
                                //     "删除" //1001
                                // ),
                            ]
                        )
                    },
                },
            ],
            currentFormData: {},
            defaultList: [],
            validateForm: {
                goodsId: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
                // type: [{ required: true, type: "array", min: 1, message: "请选择服务类型", trigger: "change" }],
            },

            showImage: false,

            selectList: [],
            typeList: [],
            saveStatus: false,
            that: this,
            suppliers: [],
            oemCode: "",
            pointList: [],
        }
    },
    methods: {
        backClick() {
            var index = parent.layer.getFrameIndex(window.name)
            if (index) {
                //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else {
                this.$router.push({
                    path: "/mallhomepage"
                })
            }
        },
        async changeModel(val) {
            this.currentFormData.pointsLevelId = ""
            await this.getModel(val)
        },
        //   切换状态
        changeSwitch(val, id) {
            return
            this.$post("/pension/api/pc/elderly/guideItem/updateStatus", {
                id,
                status: val,
            }).then(res => {
                if (res.code != 200) {
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
        },
        // 关闭弹窗
        saveStatusFn(status) {
            this.saveStatus = status
        },

        submit() {
            // this.$refs.form.validate(status => {
            //     if (status) {
            if (!this.currentFormData.goodsId || this.currentFormData.goodsId == "") {
                this.$Message.warning({
                    content: "商品名称不能为空",
                    background: true,
                })
                return
            }
            var url = ""
            var cFormData = JSON.parse(JSON.stringify(this.currentFormData))
            if (cFormData.pointsGoodsId && cFormData.pointsGoodsId != "") {
                url = `/gateway/sy-points/api/sypointes/goods/updatePointsGoods`
            } else {
                url = `/gateway/sy-points/api/sypointes/goods/addPointsGoods`
            }
            // 校验必填
            this.$post(
                url,
                {
                    ...cFormData,
                },
                { "content-type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "保存成功",
                        })
                        this.getList(this.page)
                        this.saveStatusFn(false)
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "保存失败，请联系管理员处理",
                    })
                })
            //     }
            // })
        },

        append() {
            // 新增
            this.currentFormData = {}
            this.saveStatusFn(true)
        },
        select(pointsGoodsId) {
            try {
                if (pointsGoodsId) {
                    // 获取详情
                    this.currentFormData = {}
                    this.$get("/gateway/sy-points/api/sypointes/goods/queryPointsGoodsById", {
                        pointsGoodsId,
                    })
                        .then(async res => {
                            if (res.code == 200 && res.data) {
                                var cFormData = res.data
                                this.currentFormData = cFormData
                                this.$forceUpdate()
                                this.saveStatusFn(true)
                                // this.$refs.eleselect.onSearch(this.currentFormData.goodsId)
                                this.$refs.eleselect.val_text = this.currentFormData.goodsId
                                this.$refs.eleselect.onSearchSelect()
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.error({
                                background: true,
                                content: "获取数据失败，请联系管理员处理",
                            })
                        })
                }
            } catch (err) {
                console.log("err", err)
            }
        },
        onSelect(selection) {
            this.selection = selection
        },
        // deleteData(id) {
        //     this.$Modal.confirm({
        //         title: "删除确认",
        //         content: "是否删除选中的数据？",
        //         onOk: () => {
        //             this.$post("/gateway/apps/o2o/api/o2o/test15min/delete", {
        //                 id,
        //             })
        //                 .then(res => {
        //                     if (res.code == 200) {
        //                         this.getList()
        //                     } else {
        //                         this.$Message.error({
        //                             background: true,
        //                             content: "删除出错：" + res.desc,
        //                         })
        //                     }
        //                 })
        //                 .catch(err => {
        //                     this.$Message.error({
        //                         background: true,
        //                         content: "删除出措",
        //                     })
        //                 })
        //         },
        //     })
        // },
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.page = obj.page
            this.getList()
        },
        getList() {
            this.loading = true
            this.$get("/gateway/sy-points/api/sypointes/goods/queryPointGoodsByPage", {
                page: this.page,
                pageSize: this.pageSize,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.tableData = res.dataList
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                    }
                    this.$Message.destroy()
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        async getSelectList() {
            await this.$get("/gateway/sy-points/api/sypointes/pool/listAllOption").then(res => {
                this.selectList = res
            })
        },
        async getModel(poolId) {
            this.$Message.loading({
                content: "正在获取等级",
            })
            this.$get("/gateway/sy-points/api/sypointes/pool/listLevelRuleByPoolId", {
                poolId,
            }).then(res => {
                this.$Message.destroy()
                if (res && res.dataList && res.dataList.length) {
                    this.pointList = res.dataList
                } else {
                    this.pointList = []
                }
            })
        },
    },
    async created() {
        this.oemCode = parent.vue.oemInfo.oemCode
        await this.getList()
        await this.getSelectList()
    },
}
</script>

<style lang='less'>
.leftMenu {
    // position: fixed;
    // top: 60px;
    // left: 0px;
    width: calc(100% - 600px);
    // bottom: 0;
    background: #ffffff;
    // overflow: auto;
    float: left;
}
.rightEdit {
    float: left;
    // position: fixed;
    // top: 60px;
    height: 600px;
    width: 600px;
    // right: 0;
    bottom: 0;
    // overflow: auto;
    border-left: #ddd solid 1px;
}
.demo-tree-render .ivu-tree-title {
    width: calc(100% - 34px);
    padding: 10px;
    & > span {
        & > span:first-of-type {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 70%;
            display: inline-block;
        }
    }
}
.demo-tree-render .ivu-tree-arrow {
    padding: 10px;
}
.ivu-breadcrumb {
    background-color: #fff;
    line-height: 36px;
    padding: 0 20px;
    margin-bottom: 10px;
    position: relative;
}
.form {
    padding: 10px 30px 10px 10px;
    height: 100%;
    overflow: scroll;
}
.noContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    color: #999;
    font-size: 36px;
}
.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.toolsbarRight {
    display: flex;
    padding-top: 10px;
}
</style>
